import React, { useContext } from "react"
import TopicCard from "./topicCard"
import Panel from './panel'
import { ThemeContext } from "../theme-context"
import Skills from '../data/skills.json'

function Topics() {
  const { state: { style } } = useContext(ThemeContext)
  return (
    <Panel title='Skills' subtitle='Some things I know'>
      {Skills.map((skill, i) => (
        <div key={i} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
          <TopicCard topic={skill} />
        </div>
      ))}
    </Panel>
  )
}

export default Topics
