import React, { useContext } from "react"

import SidebarLayout from '../components/sidebarLayout'
import { ThemeContext } from "../theme-context"
import Projects from '../components/projects'
import ClosedProjects from '../components/closed-projects'
import Interests from '../components/interests'
import Skills from '../components/skills'
import useSiteMetadata from '../hooks/siteMetaData'

function Portfolio() {
  const { state: { style }, dispatch } = useContext(ThemeContext)
  const { layout } = useSiteMetadata()
  return <SidebarLayout>
    <Skills />
    <Interests />
    <Projects />
    <ClosedProjects />
  </SidebarLayout>
}

export default Portfolio
