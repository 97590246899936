import React, { useContext } from "react"
import { ThemeContext } from "../theme-context"
import Panel from './panel'
import TopicCard from './topicCard'
import Projects from '../data/closed-projects.json'

function ClosedProjects() {
  const { state: { style } } = useContext(ThemeContext)
  return (
    <Panel title='Closed source' subtitle='Some other things I built'>
        {Projects.map((project, i) => (
          <div key={i} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
            <TopicCard topic={project} />
          </div>
        ))}
    </Panel>
  )
}

export default ClosedProjects
